import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const savedLang = localStorage.getItem("lang") || "ru";

i18n.use(initReactI18next).init({
  resources: {
    kg: {
      translation: {
        key: "Купуялык саясаты, маалыматтарды иштетүү, кукилерди колдонуу",
        key1: "Брондоо жана башкаруу",
        key2: "Маалымат",
        key3: "Компания жөнүндө",
        key4: "Байланыштар",
        popular: "Популярдуу багыттар",
        block: "Пайдалуу блог",
        news: "Жаңылыктар",
        all: "Бардык",
        allnews: "жаңылыктар",
        accept: "Биз кабыл алабыз",
        about: "Биз жөнүндө",
        aboutText:
          "«Скай ФРУ» ЖЧКсы 2023-жылы түптөлүп, 2024-жылдын март айында №63 оператор сертификатын алган. Авиакомпаниянын учуулары ыңгайлуу Boeing 737-300 учагында аткарылат. «Sky FRU» ЖЧКсынын саясаты ыңгайлуу жана коопсуз учууларды камсыз кылууга багытталган жалпы кызыкчылыктардын жактоочуларынын бирдиктүү командасын түзүүгө багытталган. Sky FRU Airlines - бул, биринчи кезекте, позитивдүү энергияга жана мээримдүү авиацияга толгон адистердин командасы. Квалификациялуу экипаждар жана кесипкөй жер үстүндөгү кызматкерлер күн сайын биздин авиакомпания менен учкан жүргүнчүлөргө кам көрүшөт. Биз жүргүнчүлөрүбүздүн учактарыбызда күн сайын коопсуз жана жайлуу саякатка чыгышын каалайбыз, анткени биздин авиакомпаниянын урааны “ДҮЙНӨНҮ БИЙИКТИКтен ТААНЫШ”.",
        our: "Биздин өнөктөштөр",
        directions: "8 багыт",
        policy: "Жеке маалыматтарды иштетүүгө макулдук",
        policy1: "Положение о конфиденциальности",
        policy1: "Политика использование файлов cookie",

        header: "Брондоо жана башкаруу",
        header1: "Маалымат",
        header2: "Компания жөнүндө",
        header3: "Байланыштар",
      },
    },
    ru: {
      translation: {
        key: "Политика конфиденциальности, обработка данных, использование cookie",
        key1: "Бронирование и управление",
        key2: "Информация",
        key3: "О компании",
        key4: "Контакты",
        popular: "Популярные направления",
        block: "Полезный блог",
        news: "Новости",
        all: "Все",
        allnews: "новости",
        accept: "Мы принимаем",
        about: "О нас",
        aboutText:
          "ОсОО «Скай ФРУ» было образовано в 2023 году, а в марте 2024 года был получен сертификат эксплуатанта № 63. Авиарейсы авиакомпании выполняются на комфортабельном воздушном судне Boeing 737-300. Политика ОсОО «Скай ФРУ» направлена на создание единой команды приверженцев общих интересов ориентированных на предоставление комфортабельных и безопасных полетов. Авиакомпания «Скай ФРУ» — это в первую очередь команда специалистов, полных позитивной энергии и любящих авиацию. Квалифицированные экипажи и профессиональный наземный персонал заботятся о пассажирах, перелетающих нашей авиакомпанией каждый день. Мы хотим, чтобы наши пассажиры путешествовали на наших самолетах безопасно и комфортно каждый день, так как лозунг нашей авиакомпании «ВСТРЕЧАЙ МИР С ВЫСОТЫ».",
        our: "Наши партнёры",
        directions: "8 направлений",
        policy: "Согласие на обработку персональных данных",
        policy1: "Положение о конфиденциальности",
        policy1: "Политика использование файлов cookie",

        header: "Бронирование и управление",
        header1: "Информация",
        header2: "О компании",
        header3: "Контакты",
      },
    },
    en: {
      translation: {
        key: "Privacy policy, data processing, use of cookies",
        key1: "Reservations and management",
        key2: "Information",
        key3: "About the company",
        key4: "Contacts",
        popular: "Popular destinations",
        block: "Useful blog",
        news: "News",
        all: "All",
        allnews: "news",
        accept: "We accept ",
        about: "About us",
        aboutText:
          "Sky FRU LLP was established in 2023, and in March 2024 it received Operator's Certificate No. 63. The airline's flights are operated by comfortable Boeing 737-300 aircraft. The policy of Sky FRU Ltd. is aimed at creating a united team of adherents of common interests focused on providing comfortable and safe flights. Sky FRU Airlines is first of all a team of specialists full of positive energy and loving aviation. Qualified crews and professional ground staff take care of the passengers who fly with our airline every day. We want our passengers to travel safely and comfortably on our airplanes every day, as our airline's slogan is See the World from the Heights.",
        our: "Our partners",
        directions: "8 directions",
        policy: "Consent to processing of personal data",
        policy1: "Confidentiality clause",
        policy1: "Cookie Policy",

        header: "Booking and Management",
        header1: "Information",
        header2: "About Company",
        header3: "Contacts",
      },
    },
  },
  lng: savedLang,
  fallbackLng: "ru",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
